import styled from 'styled-components'
import TuxBannerPng from '../../assets/images/tux_banner_50.png'
import React from 'react'

const TuxBanner = styled.img`
  border-radius: 10px;
  height: 150px;
  width: 100%;
  object-fit: cover;
  object-position: 0% 20%;
  margin-top: -10%;
  margin-bottom: 1%;
  border: 1px solid rgb(21, 242, 201, 0.08) !important;
  box-shadow: 0 0 20px rgb(21, 242, 201, 0.08), 0 0 40px rgb(21, 242, 201, 0.08);
  -webkit-filter: brightness(90%) contrast(90%) grayscale(50%);
  filter: brightness(80%) contrast(100%);
`
export const Tux = () => <TuxBanner src={TuxBannerPng} />
