import 'styled-components/macro'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Column, { AutoColumn } from '../Column'
import { AutoRow } from '../Row'

export const AssetsContainer = styled(Column)`
  max-width: 1080px;
  width: 100%;
  background: rgb(21, 242, 201, 0.1) c;
  border: 1px solid rgb(21, 242, 201, 0.15) !important;
  //box-shadow: 0 0 15px 5px rgba(18, 176, 202, 0.2),
  //  0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 10px;
  padding: 24px;
  //border: 1px solid blue;
  flex: 1 1;
  position: relative;
  margin-bottom: 50px;
`

export function AssetsTable({ headers, children }: { headers?: string[]; children?: React.ReactNode }) {
  return (
    <>
      <AssetsContainer>
        <AssetsTableHeader headersOverride={headers} />
        {children}
      </AssetsContainer>
    </>
  )
}

export const AssetsTableHeaderText = styled(AutoColumn)`
  font-size: 1rem;
  color: #fff;
  text-align: center;
`

export const AssetsTableHeaderContainer = styled(AutoRow)`
  padding-left: 11%;
  padding-right: 5%;
  margin-bottom: 2%;
`

export const AssetRow = styled(Link)`
  text-decoration: none;
  border-radius: 10px;
  border: 1px solid transparent;
  color: #fff;
  font-size: 1.1rem;
  border: 1px solid rgb(21, 242, 201, 0.08) !important;
  //box-shadow: 0 0 5px rgba(39, 210, 234, 0.05), 0 0 7px rgba(39, 210, 234, 0.05);
  :hover,
  :focus {
    color: #fff;
    border: 1px solid rgba(11, 13, 14, 0.85);
    box-shadow: 0 0 15px 5px rgba(21, 242, 201, 0.15);
    background: linear-gradient(
      264deg,
      rgba(16, 16, 18, 0.1) 0%,
      rgba(39, 210, 234, 0.05) 25%,
      rgba(16, 16, 18, 0.1) 50%,
      rgba(39, 210, 234, 0.05) 75%,
      rgba(16, 16, 18, 0.1) 100%
    );
  }
  padding: 10px 30px;
  margin-top: 2%;
`

export function AssetsTableHeader({ headersOverride }: { headersOverride?: string[] | undefined }) {
  return (
    <AssetsTableHeaderContainer justify={'space-between'}>
      {headersOverride &&
        headersOverride.length &&
        headersOverride.map((header: string) => <AssetsTableHeaderText key={header}>{header}</AssetsTableHeaderText>)}
      {(!headersOverride || !headersOverride.length) && (
        <>
          <AssetsTableHeaderText>Asset</AssetsTableHeaderText>
          <AssetsTableHeaderText>Amount</AssetsTableHeaderText>
          <AssetsTableHeaderText>Position Value</AssetsTableHeaderText>
        </>
      )}
    </AssetsTableHeaderContainer>
  )
}
