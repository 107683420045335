export enum ChainId {
  MAINNET = 1229,
  TESTNET = 56,
  HOMESTEAD = 1,
}

export const NETWORK_URLS: {
  [chainId in ChainId]: string
} = {
  [ChainId.MAINNET]: `https://evm.exzo.network/`,
  [ChainId.TESTNET]: `https://rpc.ankr.com/bsc`,
  // From Metamask
  [ChainId.HOMESTEAD]: 'hhttps://rpc.mevblocker.io',
}
