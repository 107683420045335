/* eslint-disable @typescript-eslint/no-unused-vars */
import useScrollPosition from '@react-hook/window-scroll'
import React from 'react'
import { Text } from 'rebass'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

// import Logo from '../../assets/logo'

import { useActiveWeb3React } from '../../hooks/web3'
import { useETHBalances } from '../../state/wallet/hooks'
//import { BridgeMenu } from '../Menu/BridgeMenu'
import { MobileMenu } from '../Menu/MobileMenu'

//import { ExternalLink } from '../../theme'
import { YellowCard } from '../Card'
import Menu from '../Menu'

import Row, { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
//import Modal from '../Modal'
//import UniBalanceContent from './UniBalanceContent'
import { ChainId } from 'constants/chains'
import ExzoLogo from '../../assets/svg/logo.svg'
import { ExternalLink } from 'theme/components'

const Logo = styled.img`
  height: 30px;
`

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  display: grid;
  grid-template-columns: 120px 1fr 270px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 8px;
  z-index: 21;
  position: relative;

  /* Background slide effect on scroll. */
  background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`}
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px ${({ theme, showBackground }) => (showBackground ? theme.bg2 : 'transparent;')};
  transition: background-position 0.1s, box-shadow 0.1s;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding:  1rem;
    grid-template-columns: 120px 1fr;

  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 1rem;
  `}
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
    max-width: 960px;
    padding: 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 72px;
    border-radius: 12px 12px 0 0;
    background-color: ${({ theme }) => theme.bg1};
    backdrop-filter: blur(4px) brightness(50%) saturate(150%);
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
  `};
`
{
  /* Adjust margin-left to center text */
}
const HeaderLinks = styled(Row)`
  background: ${({ theme }) => theme.primary6};
  border: 1px solid ${({ theme }) => theme.primary6};
  margin-left: 44%;
  width: fit-content;
  padding: 4px;
  border-radius: 10px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow: auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: flex-end;
  `};
`
// const HeaderLinks = styled(Row)`
//   background: ${({ theme }) =>
//     `linear-gradient(90deg, ${theme.darkTransparent2} 0%, ${theme.secondary1_10} 50%, ${theme.darkTransparent2} 100%);`};
//   border: 1px solid #fff;
//   box-shadow: 0 0 5px rgba(39, 210, 234, 0.2), 0 0 7px rgba(39, 210, 234, 0.2);
//   margin-left: 44%;
//   width: fit-content;
//   padding: 4px;
//   border-radius: 10px;
//   display: grid;
//   grid-auto-flow: column;
//   grid-gap: 10px;
//   overflow: auto;
//   ${({ theme }) => theme.mediaWidth.upToMedium`
//     justify-self: flex-end;
//   `};
// `

const AccountElement = styled.div<{ active: boolean }>``
// const AccountElement = styled.div<{ active: boolean }>`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   background: ${({ theme }) =>
//     `linear-gradient(90deg, ${theme.darkTransparent2} 0%, ${theme.secondary1_10} 50%, ${theme.darkTransparent2} 100%);`};
//   border-radius: 8px;
//   white-space: nowrap;
//   width: 100%;
//   cursor: pointer;
//   border: 1px solid rgba(12, 92, 146, 0.3);
//   box-shadow: 0 0 15px 5px rgba(18, 176, 202, 0.2);

//   :focus {
//     border: 1px solid blue;
//   }
// `

const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const HideLarge = styled.span`
  @media screen and (min-width: 700px) {
    display: none !important;
  }
`

const NetworkCard = styled(YellowCard)`
  border-radius: 9999px;
  padding: 5px 12px;
  font-size: 0.875rem;
  border-radius: 8px !important;
  color: #fff !important;
  background-color: rgb(21, 242, 201, 0.05) !important;
  border: 1px solid rgb(21, 242, 201, 0.1) !important;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
    border-radius: 8px !important;
    color: #fff !important;
    background-color: rgb(21, 242, 201, 0.1) !important;
    border: 1px solid rgb(21, 242, 201, 0.15) !important;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    color: #fff !important;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 0.875rem;
  width: fit-content;
  font-weight: 500;
  padding: 5px 12px;

  &.${activeClassName} {
    border-radius: 0px;
    font-weight: 800;
    color: ${({ theme }) => theme.primaryText3};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.primaryText3)};
  }
`

const MainNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  width: fit-content;
  padding: 5px 12px;
  font-size: 0.875rem;
  color: rgb(161 161 170);
  &.${activeClassName} {
    border-radius: 0px;
    color: #fff;
  }

  :hover {
    border-bottom: 0.5px solid #15f2c9;
  }
  :focus {
    color: #15f2c9;
    border-bottom: 0.5px solid #15f2c9;
  }
`
const MainMenu = styled.div`
  margin-left: 42%;
  width: fit-content;
  padding: 4px;
  border-radius: 10px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow: auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: flex-end;
  `};
`
const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  width: fit-content;
  padding: 5px 12px;
  font-size: 0.875rem;
  color: rgb(161 161 170);
  &.${activeClassName} {
    border-radius: 0px;
    color: #fff;
  }

  :hover {
    color: #15f2c9;
    border-bottom: 0.5px solid #15f2c9;
  }
  :focus {
    color: #15f2c9;
    border-bottom: 0.5px solid #15f2c9;
  }
`

export const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  height: 35px;
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  color: #fff !important;
  border-radius: 16px !important;
  background-color: rgb(21, 242, 201, 0.1) !important;
  border: 1px solid rgb(21, 242, 201, 0.15) !important;

  :hover {
    cursor: pointer;
    border-bottom: 0.5px solid #15f2c9;
  }
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
    border-bottom: 0.5px solid #15f2c9;
  }

  svg {
    margin-top: 2px;
  }
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

const VerticalLine = styled.div`
  width: 1px;
  height: 1.25rem;
  margin-right: 10px;
  background-color: #15f2c9;
`

const NETWORK_LABELS: Record<ChainId, string> = {
  [ChainId.TESTNET]: 'BNB',
  [ChainId.MAINNET]: 'XZO',
  [ChainId.HOMESTEAD]: 'ETH',
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  const { t } = useTranslation()
  // const [isBridgeOpen, setIsBridgeOpen] = useState(false)

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  // const [isDark] = useDarkModeManager()

  // const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)

  const scrollY = useScrollPosition()

  return (
    <HeaderFrame showBackground={scrollY > 45}>
      {/* <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal> */}
      <HeaderRow>
        <Title href=".">
          <Logo src={ExzoLogo} />
        </Title>
      </HeaderRow>
      <HideSmall>
        <MainMenu>
          <MainNavLink
            id={`swap-nav-link`}
            to={'/swap'}
            isActive={(match, { pathname }) => Boolean(match) || pathname.startsWith('/swap')}
          >
            {t('Swap')}
          </MainNavLink>
          <MainNavLink
            id={`pool-nav-link`}
            to={'/pool'}
            isActive={(match, { pathname }) =>
              Boolean(match) || pathname.startsWith('/pools') || pathname.startsWith('/add')
            }
          >
            {t('Pool')}
          </MainNavLink>
          <MainNavLink
            id={`assets`}
            to={'/assets'}
            isActive={(match, { pathname }) => Boolean(match) || pathname.startsWith('/farm')}
          >
            {t('Assets')}
          </MainNavLink>
          <MainNavLink
            id={`farm-nav-link`}
            to={'/farm'}
            isActive={(match, { pathname }) => Boolean(match) || pathname.startsWith('/farm')}
          >
            {t('Farm')}
          </MainNavLink>
          <MainNavLink
            id={`stake-nav-link`}
            to={'/stake'}
            isActive={(match, { pathname }) => Boolean(match) || pathname.startsWith('/stake')}
          >
            {t('Stake')}
          </MainNavLink>
          <StyledExternalLink
            id="bridge-nav-link"
            href="https://bridge.exzo.network" // your external URL here
            target="_blank" // optional: to open the URL in a new tab
            rel="noopener noreferrer" // optional: additional security for links opening in a new tab
            style={{ textDecoration: 'none' }} // optional: to remove underline from link
          >
            {t('Bridge')}
          </StyledExternalLink>
        </MainMenu>
        {/* <HeaderLinks> */}
        {/* <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
            {t('swap')}
          </StyledNavLink>
          <StyledNavLink
            id={`pool-nav-link`}
            to={'/pool'}
            isActive={(match, { pathname }) =>
              Boolean(match) ||
              pathname.startsWith('/add') ||
              pathname.startsWith('/remove') ||
              pathname.startsWith('/increase') ||
              pathname.startsWith('/find')
            }
          >
            {t('Liquidity')}
          </StyledNavLink> */}
        {/*
          <StyledNavLink
            id={`assets-nav-link`}
            to={'/assets'}
            isActive={(match, { pathname }) => Boolean(match) || pathname.startsWith('/assets')}
          >
            {t('Assets')}
          </StyledNavLink>
          */}
        {/*
          <StyledNavLink
            id={`farm-nav-link`}
            to={'/farm'}
            isActive={(match, { pathname }) => Boolean(match) || pathname.startsWith('/farm')}
          >
            {t('Farm')}
          </StyledNavLink>
          <StyledNavLink
            id={`stake-nav-link`}
            to={'/stake'}
            isActive={(match, { pathname }) => Boolean(match) || pathname.startsWith('/stake')}
          >
            {t('Stake')}
          </StyledNavLink>
          <BridgeMenu />
          <StyledExternalLink id={`charts-nav-link`} href="https://info.exzo.network">
            {t('Charts')}
            <sup>↗</sup>
          </StyledExternalLink>
          */}
        {/* </HeaderLinks> */}
      </HideSmall>
      <HeaderControls>
        <VerticalLine />
        <HeaderElement>
          <HideSmall>
            {chainId && NETWORK_LABELS[chainId] && (
              <NetworkCard title={NETWORK_LABELS[chainId]}>{NETWORK_LABELS[chainId]}</NetworkCard>
            )}
          </HideSmall>
          <AccountElement active={!!account}>
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                {userEthBalance?.toSignificant(4)} <span style={{ color: '#15f2c9' }}>XZO</span>
              </BalanceText>
            ) : null}
            <Web3Status />
          </AccountElement>
        </HeaderElement>
        <HeaderElementWrap>
          <HideLarge>
            <MobileMenu />
          </HideLarge>
        </HeaderElementWrap>
        <HeaderElementWrap>
          <Menu />
        </HeaderElementWrap>
      </HeaderControls>
    </HeaderFrame>
  )
}
